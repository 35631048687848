<template>
  <v-card>
    <v-card-title class="py-3 px-4 border-bottom">
      <span class="gray--text text--darken-3 font-weight-regular">
        {{ isEditMode ? "Lifin redaktəsi" : "Yeni lif" }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-form
        id="createItemForm"
        ref="form"
        v-model="formValidation"
        lazy-validation
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col cols="3">
            <v-text-field
              type="text"
              label="Kabel Sahibi"
              filled
              dense
              v-model="formData.cableOwner"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="text"
              label="Müqavilə №"
              filled
              dense
              v-model="formData.contract"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="Əlavə №"
              filled
              dense
              required
              v-model="formData.annex"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="date"
              label="Müqavilə tarixi"
              filled
              dense
              required
              v-model="formData.contractDate"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="Qovşaq"
              filled
              dense
              v-model="formData.nodeSide"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="text"
              label="Xidmət ərazisi"
              filled
              dense
              v-model="formData.serviceSide"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="text"
              label="Xidmət nöqtəsinin geolokasiyası"
              filled
              dense
              v-model="formData.serviceSideLocation"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="number"
              label="Lif say"
              filled
              dense
              required
              v-model="formData.lifCount"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="number"
              label="Məsafə (metr)"
              filled
              dense
              v-model="formData.length"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="number"
              label="Vahidin qiyməti"
              filled
              dense
              v-model="formData.unitPrice"
              required
            ></v-text-field>
          </v-col>

           <v-col cols="3">
            <v-text-field
              type="number"
              label="Aylıq qiyməti"
              filled
              dense
              v-model="formData.monthlyFee"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-textarea
              type="text"
              label="Qeyd"
              filled
              dense
              required
              auto-grow
              rows="1"
              v-model="formData.note"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
      <v-btn color="gray lighten-3" depressed @click="toggleDialog">
        Bağla
      </v-btn>
      <v-btn
        :loading="isSubmittingForm"
        type="submit"
        form="createItemForm"
        color="success"
        depressed
      >
        {{ isEditMode ? "Redaktə et" : "Yarat" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    toggleDialog: {
      type: Function,
      required: true,
    },
    isSubmittingForm: {
      type: Boolean,
      required: true,
    },
    selectedItem: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      formValidation: false,
      formData: {
        cableOwner: null,
        contract: null,
        annex: null,  
        contractDate: null,
        nodeSide: null,
        serviceSide: null,
        serviceSideLocation: null,
        lifCount: null,
        length: null,
        unitPrice: null,
        monthlyFee: null,
        note: null,
      },
      formRules: {},
    };
  },
  computed: {
    isEditMode() {
      return (
        this.selectedItem && this.selectedItem.id && this.selectedItem.id > 0
      );
    },
  },
  watch: {
    selectedItem: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.formData = { ...newVal };
          this.formData.contractDate = this.convertDate(newVal.contractDate);
        } else {
          this.resetFormData();
        }
      },
    },
  },
  methods: {
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("formSubmit", this.formData);
      }
    },
    resetFormData() {
      this.formData = {
        cableOwner: null,
        contract: null,
        annex: null,  
        contractDate: null,
        nodeSide: null,
        serviceSide: null,
        serviceSideLocation: null,
        lifCount: null,
        length: null,
        unitPrice: null,
        monthlyFee: null,
        note: null,
      };
      this.$refs.form && this.$refs.form.resetValidation();
    },
    convertDate(date) {
      if (date != null) return this.$moment(date).format("YYYY-MM-DD");
      else return null;
    },
  },
};
</script>
