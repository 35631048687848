<template>
  <v-container fluid class="down-top-padding">
    <div id="itemsPage" class="page">
      <div class="page__header ">
        <Toolbar
          :showFiltersPanel="showFiltersPanel"
          @showFiltersPanelChanged="handleShowFiltersPanelChanged"
          @toggleDialog="toggleDialog"
        />
      </div>
      <div class="page__body pa-4">
        <div class="mb-4" v-show="showFiltersPanel">
          <v-row class="my-0">
            <v-col md="12">
              <Filters @filtersChanged="handleFiltersChanged" />
            </v-col>
          </v-row>
        </div>

        <v-dialog v-model="dialog" persistent scrollable max-width="1200px">
          <Form
            v-if="dialog"
            @formSubmit="handleFormSubmit"
            :toggleDialog="toggleDialog"
            :isSubmittingForm="isSubmittingForm"
            :selectedItem="selectedItem"
          />
        </v-dialog>
        <List
          :page="page"
          :items="items"
          :totalCount="totalCount"
          :isFetchingItems="isFetchingItems"
          :itemsPerPage="itemsPerPage"
          @editItem="handleEditItem"
          @deleteItem="handleDeleteItem"
          @optionsChanged="handleOptionsChanged"
          @changeStatus="handleChangeStatus"
        />
      </div>
      <ConfirmationPopup
        id="item-delete-modal"
        v-if="itemToDelete"
        title="Lifin silinməsi"
        :dialog="confirmationDialog"
        :loading="isDeletingItem"
        @confirm="handleDeleteConfirmation"
        @cancel="handleDeleteCancellation"
      >
        Lifi silmək istədiyinizə əminsiniz?
      </ConfirmationPopup>
    </div>
  </v-container>
</template>

<script>
import API from "@/services";
import ConfirmationPopup from "@/shared/Common/ConfirmationPopup.vue";
import List from "./components/List/index.vue";
import Form from "./components/Form/index.vue";
import Toolbar from "./components/Toolbar/index.vue";
import Filters from "./components/Toolbar/components/Filters.vue";
import bus from "../../../event-bus";

export default {
  components: {
    List,
    Form,
    Toolbar,
    ConfirmationPopup,
    Filters,
  },
  data() {
    return {
      dialog: false,
      confirmationDialog: false,
      selectedItem: null,
      itemToDelete: null,
      page: 1,
      isFetchingItems: false,
      isSubmittingForm: false,
      isDeletingItem: false,
      totalCount: 0,
      itemsPerPage: 10,
      items: [],
      filters: {},
      showFiltersPanel: false,
    };
  },
  async created() {
    this.fetchItems();
  },
  methods: {
    fetchItems() {
      this.isFetchingItems = true;
      API.fetchLifItems({
        limit: this.itemsPerPage,
        offset: (this.page - 1) * this.itemsPerPage,
        ...this.filters,
      })
        .then((response) => {
          this.items = response.items;
          this.totalCount = response.totalCount;
        })
        .catch(() => {
           bus.$emit("notificationMessage", {
            color: "error",
            text: "Liflərin siyahısını əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    handleFormSubmit(formData) {
      this.isSubmittingForm = true;
      const request = this.selectedItem
        ? API.updateLifItem(this.selectedItem.id, formData)
        : API.insertLifItem(formData);

      request
        .then(() => {
          this.toggleDialog();
          this.fetchItems();
        })
        .catch(() => {
           bus.$emit("notificationMessage", {
            color: "error",
            text: "Əməliyyatı aparmaq mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isSubmittingForm = false;
        });
    },
    handleEditItem(item) {
      this.isFetchingItems = true;
      API.fetchLifItemById(item.id)
        .then((response) => {
          this.selectedItem = response;
          this.dialog = true;
        })
        .catch(() => {
           bus.$emit("notificationMessage", {
            color: "error",
            text: "Məlumatı əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },    
    handleDeleteItem(item) {
      this.itemToDelete = item;
      this.confirmationDialog = true;
    },
    handleDeleteConfirmation() {
      this.isDeletingitem = true;
      API.deleteLifItem(this.itemToDelete.id)
        .then(() => {
          this.confirmationDialog = false;
          this.itemToDelete = null;
          this.fetchItems();
        })
        .catch(() => {
           bus.$emit("notificationMessage", {
            color: "error",
            text: "Lifi silmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isDeletingItem = false;
        });
    },
    handleDeleteCancellation() {
      this.confirmationDialog = false;
      this.itemToDelete = null;
    },
    handleFiltersChanged(filters) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.page = 1;
        this.filters = { ...filters };
        this.fetchItems();
      }
    },
    handleChangeStatus(item) {
      this.isFetchingItems = true;

      API.changeLifItemStatus(item.id)
        .then(() => {
          this.fetchItems();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Lifin statusunu dəyişmək mümkün olmadı.",
          });
        });
    },
    toggleDialog() {
      this.dialog = !this.dialog;
      if (!this.dialog) {
        this.selectedItem = null;
      }
    },   
    handleOptionsChanged(item) {
      if (this.page !== item.page || this.itemsPerPage !== item.itemsPerPage) {
        this.page = item.page;
        this.itemsPerPage = item.itemsPerPage;

        this.fetchItems();
      }
    },
    handleShowFiltersPanelChanged(item) {
      this.showFiltersPanel = item;
    },
  },
};
</script>
